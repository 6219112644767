import React, { FC, useRef } from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { Container } from 'layout';

import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

import { YoutubeVideoProps } from './models';

import './YoutubeVideo.scss';

const Youtube = loadable(() => import('react-youtube'));

const YoutubeVideo: FC<YoutubeVideoProps> = ({ name, url, className }) => {
  const youtubeRef = useRef(null);

  const entry = useIntersectionObserver(youtubeRef, { freezeOnceVisible: true });
  const isVisible = !!entry?.isIntersecting;

  const videoId = url.replace(/.*watch\?v=/i, '');

  const renderVideo = isVisible ? (
    <Youtube
      data-testid="youtube-frame"
      aria-label={name}
      videoId={videoId}
      className={classNames('youtube-video__frame', className)}
    />
  ) : null;

  return (
    <Container innerRef={youtubeRef} data-testid="youtube-video" className="youtube-video">
      {renderVideo}
    </Container>
  );
};

export default YoutubeVideo;
