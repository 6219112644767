import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner, {
  BannerBackgroundColor,
  BannerContentColor,
  BannerVariant,
} from 'components/Banner';
import Testimonials, { TestimonialsVariant } from 'components/Testimonials';
import YoutubeVideo from 'components/YoutubeVideo';
import RelatedProducts, { RELATED_PRODUCTS_VARIANTS } from 'containers/RelatedProducts';

const { PRODUCT, PROMO_SECONDARY } = BannerVariant;
const { PURPLE, MINT } = BannerBackgroundColor;
const { DARK_GREEN } = BannerContentColor;
const { TERTIARY } = TestimonialsVariant;
const { LISTING } = RELATED_PRODUCTS_VARIANTS;

const AboutProductsPage: FC<AboutProductsPageTypes.AboutProductsProps> = ({
  data: {
    aboutProducts: {
      urls,
      seo,
      heroBanner,
      footerBanner,
      relatedProducts: { ctaText },
      aboutProductVideo,
      iconSection,
    },
    allProductDetail: { nodes: relatedProductsNodes },
  },
}) => {
  const { title, keywords, description } = seo;
  const renderVideo = (): JSX.Element | null => {
    if (!aboutProductVideo.url || aboutProductVideo.url === '#') return null;

    return <YoutubeVideo {...aboutProductVideo} className="hide--on-md" />;
  };

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner variant={PRODUCT} backgroundColor={PURPLE} {...heroBanner} />
      <Container fluid>
        <Testimonials {...iconSection} variant={TERTIARY} />
        <RelatedProducts ctaText={ctaText} products={relatedProductsNodes} variant={LISTING} />
        {renderVideo()}
      </Container>
      <Banner
        variant={PROMO_SECONDARY}
        contentColor={DARK_GREEN}
        backgroundColor={MINT}
        {...footerBanner}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String], $relatedProductsLinks: [String]) {
    aboutProducts(lang: { eq: $lang }, url: { in: $path }) {
      ...AboutProductsPageFragment
    }
    allProductDetail(filter: { url: { in: $relatedProductsLinks } }) {
      nodes {
        ...ProductDetailPageFragment
      }
    }
  }
`;

export default AboutProductsPage;
